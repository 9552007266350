<!--
  PACKAGE_NAME : src\pages\esp\system\menu\list.vue
  FILE_NAME : list
  AUTHOR : devyoon91
  DATE : 2024-08-26
  DESCRIPTION : 메뉴 설정
-->
<template>
  <div>
    <div class="page-sub-box tree-box tree-header mar_b10">
      <div class="head-btn-box01">
        <div class="head-btn-left">
          <DxButton
            text="대분류 등록"
            type="button"
            class="btn_XS default filled add1"
            :height="30"
            @click="
              onOpenModal(
                'ModalMenuConfig',
                {
                  name: 'rootMenu',
                  title: '대메뉴 등록',
                  buttons: {
                    save: { text: '등록' },
                    cancel: { text: '취소' },
                  },
                  width: '600',
                  height: '400',
                },
                {
                  id: null,
                  menuDepth: 1,
                },
              )
            "
          />
        </div>
      </div>
      <div class="head-btn-box01">
        <div class="head-btn-left">
          <DxButton
            hint="목록 펼치기"
            template="<span class='mdi mdi-folder-open'></span>"
            :height="30"
            type="button"
            class="btn_XS white outlined"
            @click="onUnFoldAll"
          />
          <DxButton
            hint="목록 접기"
            template="<span class='mdi mdi-folder'></span>"
            :height="30"
            type="button"
            class="btn_XS white outlined"
            @click="onFoldAll"
          />
          <DxButton
            template="<span class='mdi mdi-chevron-double-up'></span>"
            :height="30"
            type="button"
            class="btn_XS white outlined"
            @click="onMenuSort(0)"
          />
          <DxButton
            template="<span class='mdi mdi-chevron-up'></span>"
            :height="30"
            type="button"
            class="btn_XS white outlined"
            @click="onMenuSort(1)"
          />
          <DxButton
            template="<span class='mdi mdi-chevron-down'></span>"
            :height="30"
            type="button"
            class="btn_XS white outlined"
            @click="onMenuSort(2)"
          />
          <DxButton
            template="<span class='mdi mdi-chevron-double-down'></span>"
            :height="30"
            type="button"
            class="btn_XS white outlined"
            @click="onMenuSort(3)"
          />
          <DxButton template="순서 저장" :height="30" type="button" class="btn_XS white outlined" @click="onSaveSort" />
        </div>
        <div class="head-btn-right"></div>
      </div>
    </div>

    <div class="page-sub-box tree-box tree-contents pad_bo100">
      <div class="layout-cut-box clearfix tree-box00">
        <div class="layout-cut-left tree-box01 fl">
          <div class="treemenu-set">
            <!--트리메뉴 영역 정의-->
            <DxTreeList
              id="menuList"
              :data-source="tree.menuList"
              :root-value="-1"
              :expanded-row-keys="tree.expandedRowKeys"
              :selected-row-keys="tree.selectedRowKeys"
              :focused-row-key="tree.focusedRowKey"
              :show-column-headers="true"
              :show-row-lines="true"
              :show-column-lines="false"
              :show-borders="false"
              :column-auto-width="true"
              key-expr="id"
              parent-id-expr="parentId"
              @selection-changed="selectionChanged"
              :on-row-click="onClickRow"
              :no-data-text="noDataText(tree.menuList.length)"
              :height="750"
            >
              <DxSelection :recursive="tree.recursive" mode="single" />
              <DxRowDragging
                :on-drag-change="onDragChange"
                :on-reorder="onReorder"
                :allow-drop-inside-item="tree.allowDropInsideItem"
                :allow-reordering="tree.allowReordering"
                :show-drag-icons="tree.showDragIcons"
              />
              <DxColumn caption="메뉴명" data-field="menuNm" :allow-sorting="false" />
              <DxColumn caption="" data-field="id" cell-template="removeTemplate" alignment="center" :visible="true" :width="80" />

              <template #removeTemplate="{ data }">
                <div>
                  <DxButton text="" template="<span class='mdi mdi-trash-can'></span>" @click="onDeleteData(data)" />
                  <DxButton text="" template="<span class='mdi mdi-content-copy'></span>" @click="showModalCopyMenu(data)" />
                </div>
              </template>
            </DxTreeList>
          </div>
        </div>
        <div class="layout-cut-right tree-box02 fr">
          <template v-if="tree.focusedRowKey">
            <table class="table_form line-bin">
              <caption>
                <strong>운영 관리</strong>
              </caption>
              <colgroup>
                <col style="width: 120px" />
                <col style="width: auto" />
              </colgroup>
              <thead class="sub_title_txt inner-header">
                <tr>
                  <td colspan="2"><h2>메뉴 정보</h2></td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row"><label for="label1">상위 메뉴</label></th>
                  <td id="label1">
                    <span v-if="menuFormData.data.menuDepth === 2">
                      <DxSelectBox
                        placeholder="선택"
                        :items="getUpperMenu(1)"
                        display-expr="menuNm"
                        value-expr="id"
                        v-model="menuFormData.data.parentId"
                        styling-mode="outlined"
                        :width="200"
                        :height="34"
                      >
                        <DxValidator>
                          <DxRequiredRule message="상위메뉴는 필수입니다." />
                        </DxValidator>
                      </DxSelectBox>
                    </span>
                    <span v-else-if="menuFormData.data.menuDepth === 3">
                      <DxSelectBox
                        placeholder="선택"
                        :items="getUpperMenu(2)"
                        display-expr="menuNm"
                        value-expr="id"
                        v-model="menuFormData.data.parentId"
                        styling-mode="outlined"
                        :width="200"
                        :height="34"
                      >
                        <DxValidator>
                          <DxRequiredRule message="상위메뉴는 필수입니다." />
                        </DxValidator>
                      </DxSelectBox>
                    </span>

                    <span v-else>
                      {{ parentMenu.menuNm }}
                    </span>
                  </td>
                </tr>
                <tr>
                  <th scope="row"><label for="label2">메뉴 아이디</label></th>
                  <td>{{ menuFormData.data.id }}</td>
                </tr>
                <tr>
                  <th scope="row"><label for="label3">메뉴명*</label></th>
                  <td>
                    <DxTextBox width="200" v-model="menuFormData.data.menuNm" styling-mode="outlined">
                      <DxValidator>
                        <DxRequiredRule message="메뉴명은 필수입니다." />
                      </DxValidator>
                    </DxTextBox>
                    {{ menuNmStrSize }}/{{ menuFormData.limitLength.menuNm }}
                  </td>
                </tr>
                <tr>
                  <th scope="row"><label for="label3">메뉴유형*</label></th>
                  <td>
                    <DxSelectBox
                      placeholder="선택"
                      :items="enums.common.menuType.values"
                      display-expr="label"
                      value-expr="value"
                      v-model="menuFormData.data.menuTypeCd"
                      styling-mode="outlined"
                      :width="200"
                      :height="34"
                      item-template="itemTemplate"
                    >
                      <template #itemTemplate="{ data }">
                        <div :title="getMenuTypeTooltip(data.value)">
                          {{ data.label }}
                        </div>
                      </template>
                      <DxValidator>
                        <DxRequiredRule message="메뉴유형은 필수입니다." />
                      </DxValidator>
                    </DxSelectBox>
                  </td>
                </tr>
                <tr v-if="menuFormData.data.menuDepth === 1">
                  <th scope="row"><label for="label3">메뉴아이콘</label></th>
                  <td>
                    <DxSelectBox
                      id="custom-templates"
                      :data-source="theme.menuIcons"
                      display-expr="label"
                      value-expr="value"
                      v-model="menuFormData.data.menuIcon"
                      field-template="field"
                      item-template="item"
                      :width="200"
                      styling-mode="outlined"
                    >
                      <template #field="{ data }">
                        <div style="vertical-align: middle; display: flex">
                          <div style="padding-top: 3px; padding-left: 2px">
                            <img :src="data.src" width="23" height="23" />
                          </div>

                          <DxTextBox id="menu_config_menu_icon" :value="data && data.label" :read-only="true" />
                        </div>
                      </template>
                      <template #item="{ data }">
                        <div>
                          <img :src="data.src" width="23" height="23" />
                          <span style="vertical-align: middle">
                            {{ data.label }}
                          </span>
                        </div>
                      </template>
                    </DxSelectBox>
                  </td>
                </tr>
                <tr v-if="menuFormData.data.menuTypeCd === enums.common.menuType.NORMAL_PAGE.value
                 || menuFormData.data.menuTypeCd === enums.common.menuType.LINK.value">
                  <th scope="row"><label for="label3">Page URl</label></th>
                  <td>
                    <DxTextBox width="200" v-model="menuFormData.data.pageUrl" styling-mode="outlined" />
                    {{ pageUrlStrSize }}/{{ menuFormData.limitLength.pageUrl }}자
                  </td>
                </tr>
                <tr>
                  <th scope="row"><label for="label3">Page Data</label></th>
                  <td>
                    <DxButton
                      text="수정"
                      :width="80"
                      :height="30"
                      type="edit"
                      :use-submit-behavior="true"
                      class="btn_XS white filled"
                      @click="
                        onOpenModal(
                          'ModalMenuConfigPageData',
                          {
                            name: 'pageData',
                            title: 'Page Data 수정',
                            buttons: {
                              save: { text: '수정' },
                              cancel: { text: '취소' },
                            },
                            width: '90%',
                            height: '90%',
                          },
                          {
                            id: menuFormData.data.id,
                            pageData: menuFormData.data.pageData,
                            menuNm: menuFormData.data.menuNm,
                          },
                        )
                      "
                    />
                  </td>
                </tr>
                <tr>
                  <th scope="row"><label for="label3">사용여부</label></th>
                  <td>
                    <DxSwitch @value-changed="viewFlValueChange($event)" :value="getViewFl" />
                  </td>
                </tr>
                <tr v-if="menuFormData.data.menuDepth < 4">
                  <th scope="row"><label for="label3">하위메뉴</label></th>
                  <td>
                    <DxButton text="하위메뉴 추가" class="btn_XS white outlined add2" :height="30" @click="onAddSubMenu" />
                  </td>
                </tr>
              </tbody>
            </table>
          </template>
          <template v-else>
            <div class="noformdata">메뉴정보를 선택하세요.</div>
          </template>
        </div>
      </div>
    </div>

    <section v-if="tree.focusedRowKey" class="terms bottom-btn-box">
      <div class="page-sub-box">
        <h2 class="hidden">일반 버튼</h2>
        <div class="bottom-btn-wrap">
          <DxButton
            text="저 장"
            class="btn_XS default filled txt_S medium"
            :width="120"
            :height="40"
            :use-submit-behavior="true"
            @click="onSaveFormData"
          />
          <DxButton text="취 소" class="btn_XS white filled txt_S medium" :width="120" :height="40" @click="onCancelFormData" />
        </div>
      </div>
    </section>

    <DxPopup
      :show-title="true"
      :title="modal.initData ? modal.initData.title : null"
      :min-width="modal.initData ? modal.initData.width : null"
      :width="modal.initData ? modal.initData.width : null"
      :min-height="modal.initData ? modal.initData.height : null"
      :height="modal.initData ? modal.initData.height : null"
      :drag-enabled="true"
      :resize-enabled="true"
      :show-close-button="true"
      :close-on-outside-click="false"
      :visible="modal.isOpened"
      @hiding="isOpenModal(false)"
    >
      <template #content>
        <div>
          <component
            :is="modal.currentComponent"
            :menuList="tree.menuList"
            :contentData="modal.contentData"
            v-model="modal.contentData"
            :options="modal.editorOption"
            :history="modal.menuConfigHistories"
            :menuId="menuId"
            ref="menuConfigPageData"
          ></component>
        </div>
      </template>

      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        :visible="
          modal.initData.hasOwnProperty('buttons')
            ? modal.initData.buttons.hasOwnProperty('save')
              ? modal.initData.buttons.hasOwnProperty('save')
              : !modal.initData.buttons.hasOwnProperty('save')
            : false
        "
        :options="{
          elementAttr: {
            class: 'default filled txt_S medium',
          },
          text: this.modal.initData.hasOwnProperty('buttons')
            ? this.modal.initData.buttons.hasOwnProperty('save')
              ? this.modal.initData.buttons.save.text
              : ''
            : '',
          width: '120',
          height: '40',
          useSubmitBehavior: true,
          onClick: () => {
            this.onConfirmModal();
          },
        }"
      />
      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        :visible="
          modal.initData.hasOwnProperty('buttons')
            ? modal.initData.buttons.hasOwnProperty('cancel')
              ? modal.initData.buttons.hasOwnProperty('cancel')
              : !modal.initData.buttons.hasOwnProperty('cancel')
            : false
        "
        :options="{
          elementAttr: {
            class: 'white filled txt_S medium',
          },
          text: this.modal.initData.hasOwnProperty('buttons')
            ? this.modal.initData.buttons.hasOwnProperty('cancel')
              ? this.modal.initData.buttons.cancel.text
              : ''
            : '',
          width: '120',
          height: '40',
          onClick: () => {
            this.isOpenModal(false);
          },
        }"
      />
    </DxPopup>
  </div>
</template>

<script>
  import { DxColumn, DxRowDragging, DxSelection, DxTreeList } from 'devextreme-vue/tree-list';
  import { DxButton } from 'devextreme-vue/button';
  import { DxTextBox } from 'devextreme-vue/text-box';
  import DxSwitch from 'devextreme-vue/switch';
  import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
  import ModalMenuConfig from '@/pages/esp/system/menu/modal-menu-config.vue';
  import ModalMenuCopy from '@/pages/esp/system/menu/modal-menu-copy.vue';
  import ModalMenuConfigPageData from '@/pages/esp/system/menu/modal-menu-page-data.vue';
  import { DxRequiredRule, DxValidator } from 'devextreme-vue/validator';
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import DxDropDownBox from 'devextreme-vue/drop-down-box';
  import {
    DxColumn as DxColumnGrid,
    DxDataGrid,
    DxFilterRow,
    DxPager,
    DxPaging,
    DxScrolling,
    DxSelection as DxSelectionGrid,
  } from 'devextreme-vue/data-grid';
  import { cloneObj, isEmpty, isSuccess } from '@/plugins/common-lib';
  import enums from '@/configs/enums';

  export default {
    components: {
      DxDataGrid,
      DxColumnGrid,
      DxSelectionGrid,
      DxPaging,
      DxPager,
      DxFilterRow,
      DxScrolling,
      DxTreeList,
      DxSelection,
      DxColumn,
      DxRowDragging,
      DxButton,
      DxTextBox,
      DxSwitch,
      DxPopup,
      DxToolbarItem,
      ModalMenuConfig,
      DxValidator,
      DxRequiredRule,
      DxSelectBox,
      ModalMenuConfigPageData,
      DxDropDownBox,
      ModalMenuCopy,
    },
    data() {
      return {
        originPageData: null,
        config: {
          pageSetting: {
            config: {},
          },
        },
        menuId: 0,
        modal: {
          isOpened: false,
          currentComponent: null,
          initData: {},
          contentData: null,
          xmlModalOption: {
            title: 'XML 관리',
            width: '80%',
            height: '85%',
            minWidth: null,
            minHeight: null,
          },
          editorOption: {
            type: 'REPORT',
            menuNm: '',
            menuId: this.menuId,
            useRight: true,
            rsWidth: '30%', // 우측 섹션 넓이
          },
          menuConfigHistories: [],
        },
        menuFormData: {
          data: {},
          limitLength: {
            menuNm: 20,
            pageUrl: 100,
          },
          iconData: [],
        },
        tree: {
          menuList: [],
          expandedRowKeys: [],
          selectedRowKeys: [],
          focusedRowKey: null,
          selectedRowsData: [],
          recursive: true,
          selectionMode: 'all', // 'all' or 'excludeRecursive' or 'leavesOnly'
          allowDropInsideItem: false,
          allowReordering: true,
          showDragIcons: true,
          selectedData: null,
        },
      };
    },
    computed: {
      enums() {
        return enums;
      },
      theme() {
        return this.$_theme;
      },
      getViewFl() {
        return this.menuFormData.data.viewFl === 'Y';
      },
      parentMenu() {
        if (this.menuFormData?.data?.parentId && this.tree.menuList.length > 0) {
          return this.tree.menuList.find(d => d.id === this.menuFormData.data.parentId);
        }
        return { menuNm: '-' };
      },
      menuNmStrSize() {
        const menuNm = this.menuFormData.data.menuNm;
        return isEmpty(menuNm) ? 0 : menuNm.length;
      },
      pageUrlStrSize() {
        const pageUrl = this.menuFormData.data.pageUrl;
        return isEmpty(pageUrl) ? 0 : pageUrl.length;
      },
    },
    methods: {
      showModalCopyMenu(data) {
        this.onOpenModal(
          'ModalMenuCopy',
          {
            name: 'copyMenu',
            title: '메뉴 복사',
            buttons: {
              save: { text: '복사' },
              cancel: { text: '취소' },
            },
            width: '300',
            height: '220',
          },
          {
            id: null,
            menuDepth: 1,
            data: data.row.node,
            menuFormData: this.menuFormData,
          },
        );
      },
      /**
       * 상위메뉴 리스트 가져오기
       * @param depth
       * @return {*[]}
       */
      getUpperMenu(depth) {
        if (this.tree.menuList.length === 0) return;
        return this.tree.menuList.filter(d => d.menuDepth === depth);
      },
      onFoldAll() {
        this.tree.expandedRowKeys = [];
      },
      onUnFoldAll() {
        if (this.tree.menuList.length === 0) return;
        this.tree.expandedRowKeys = this.tree.menuList.map(d => d.id);
      },
      async onSaveFormData() {
        await this.saveMenu([this.menuFormData.data]);
      },
      async onConfirmModal() {
        switch (this.modal.initData.name) {
          case 'rootMenu':
            if (!this.modal.contentData.menuIcon) return;
            await this.saveMenu([this.modal.contentData]);
            break;
          case 'subMenu':
            if (!this.modal.contentData.menuNm || !this.modal.contentData.menuTypeCd) return;
            await this.saveMenu([this.modal.contentData]);
            break;
          case 'pageData':
            this.menuFormData.data.pageData = this.modal.contentData.pageData;
            if (this.originPageData !== null && this.originPageData === this.modal.contentData?.pageData) {
              await this.$_Msg(this.$_msgContents('CMN_NO_CHANGED'));
              return false;
            }
            await this.saveMenu([
              {
                id: this.modal.contentData.id,
                pageData: this.modal.contentData.pageData,
                description: this.modal.contentData.description,
              },
            ]);
            break;
          case 'copyMenu':
            await this.copyMenuData(this.modal.contentData);
            break;
        }
      },
      async copyMenuData(data) {
        let copyData = [];
        // 선택한 메뉴 우선 복사
        const menuItem = data.menuFormData.data;
        const itemIndex = this.tree.menuList.indexOf(menuItem);

        let copyItem = this.$_commonlib.cloneObj(menuItem);
        let id = 0;
        copyItem.id = null;
        copyItem.subId = ++id;
        copyItem.menuNm = copyItem.menuNm + ' 복사본';
        copyItem['isCopyed'] = true;
        this.tree.menuList.splice(itemIndex + 1, 0, copyItem);
        copyData.push(copyItem);

        if (data.isCopyChildren) {
          //하위메뉴까지 복사를 선택한경우
          if (data.data.hasChildren) {
            // 하위메뉴를 가지고 있다면
            data.data.children.forEach(element => {
              let childMaxId = ++id;
              const childMenuItem = element.data;
              let copyChildItem = this.$_commonlib.cloneObj(childMenuItem);
              copyChildItem.id = null;
              copyChildItem.subId = ++childMaxId;
              copyChildItem.menuNm = copyChildItem.menuNm + ' 복사본';
              copyChildItem.parentId = copyItem.subId;
              copyChildItem['isCopyed'] = true;
              const copyItemIndex = this.tree.menuList.indexOf(childMenuItem);
              this.tree.menuList.splice(copyItemIndex + 1, 0, copyChildItem);
              copyData.push(copyChildItem);

              if (element.hasChildren) {
                element.children.forEach(element2 => {
                  let childMaxId = ++id;
                  const childMenuItem2 = element2.data;
                  let copyChildItem2 = this.$_commonlib.cloneObj(childMenuItem2);
                  copyChildItem2.id = null;
                  copyChildItem2.subId = ++childMaxId;
                  copyChildItem2.menuNm = copyChildItem2.menuNm + ' 복사본';
                  copyChildItem2.parentId = copyChildItem.subId;
                  copyChildItem2['isCopyed'] = true;
                  const copyItemIndex2 = this.tree.menuList.indexOf(childMenuItem2);
                  this.tree.menuList.splice(copyItemIndex2 + 1, 0, copyChildItem2);
                  copyData.push(copyChildItem2);

                  if (element2.hasChildren) {
                    element2.children.forEach(element3 => {
                      let childMaxId = ++id;
                      const childMenuItem3 = element3.data;
                      let copyChildItem3 = this.$_commonlib.cloneObj(childMenuItem3);
                      copyChildItem3.id = null;
                      copyChildItem3.subId = ++childMaxId;
                      copyChildItem3.menuNm = copyChildItem3.menuNm + ' 복사본';
                      copyChildItem3.parentId = copyChildItem2.subId;
                      copyChildItem3['isCopyed'] = true;
                      const copyItemIndex3 = this.tree.menuList.indexOf(childMenuItem3);
                      this.tree.menuList.splice(copyItemIndex3 + 1, 0, copyChildItem3);
                      copyData.push(copyChildItem3);
                    });
                  }
                });
              }
            });
          }
        }

        const payload = {
          actionname: 'MENU_LIST_COPY',
          data: copyData,
          useErrorPopup: true,
        };

        const res = await this.CALL_API(payload);
        if (isSuccess(res)) {
          await this.isOpenModal(false);
          if (await this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }))) {
            await this.$_setSessionStorageMenu(); //메뉴 sessionStorage 재설정
            await this.selectMenuList();
          }
        } else {
          this.$_Msg(`${this.$_msgContents('CMN_ERROR')}\n${res.data.header?.resMsg}`);
        }
      },
      /**
       * 메뉴 저장/수정
       *
       * @param dataList
       * @return {Promise<void>}
       */
      async saveMenu(dataList) {
        const data = dataList;
        if (typeof data[0].description === 'undefined') {
          data[0].description = null;
        }

        const payload = {
          actionname: 'MENU_LIST_SAVE',
          data: dataList,
        };

        //순서 값 설정
        if (data[0].id === null) {
          data[0].menuOrd = this.tree.menuList.length;
        }

        if (await this.$_Confirm('현재의 작업을 저장하시겠습니까?')) {
          const res = await this.CALL_API(payload);
          if (isSuccess(res)) {
            await this.isOpenModal(false);
            if (await this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }))) {
              this.clearData();
              await this.$_setSessionStorageMenu(); //메뉴 sessionStorage 재설정
              await this.selectMenuList();
            }
          }
        }
      },
      onOpenModal(componentNm, componentInitData, data) {
        this.modal.currentComponent = componentNm; //set dynamic component name in modal body slot
        this.modal.initData = componentInitData; //set init modal templet
        this.modal.editorOption.menuNm = componentNm;

        this.modal.contentData = data;
        this.menuId = data.id;

        if (this.modal.initData.name === 'subMenu') {
          if (!this.tree.focusedRowKey) {
            this.$_Msg(this.$_msgContents('REQUIRED_PARENT_MENU'));
            return;
          }

          if (data.menuDepth > 4) {
            this.$_Msg(this.$_msgContents('CHECK_MENU_DEPTH'));
            return;
          }
        }

        this.isOpenModal(true);
      },
      async isOpenModal(data) {
        this.originPageData = this.menuFormData.data.pageData;
        if (!data && this.$refs?.menuConfigPageData?.clearMenuConfigData) {
          await this.$refs['menuConfigPageData'].clearMenuConfigData();
        }

        this.modal.isOpened = data;
        if (data) {
          this.modal.editorOption.menuId = this.menuId;
        } else {
          this.modal.currentComponent = null;
          this.modal.initData = {};
        }
      },
      /** @description: 사용여부 이벤트 */
      viewFlValueChange(e) {
        this.menuFormData.data.viewFl = e.value ? 'Y' : 'N';
      },
      /** @description: 하위 메뉴 추가 버튼 클릭 이벤트 */
      onAddSubMenu() {
        this.onOpenModal(
          'ModalMenuConfig',
          {
            name: 'subMenu',
            title: '하위메뉴 등록',
            buttons: {
              save: { text: '등록' },
              cancel: { text: '취소' },
            },
            width: '600',
            height: '450',
          },
          {
            id: null,
            menuDepth: this.menuFormData.data.menuDepth ? this.menuFormData.data.menuDepth + 1 : null,
            parentId: this.menuFormData.data.id,
          },
        );
      },
      onMenuSort(type) {
        // 0 :first 1:up 2: down 3:last 3
        let menuItem = this.tree.menuList.find(d => d.id === this.menuFormData.data.id),
          groupList,
          preItemCopy,
          nextItemCopy,
          preItemIndex,
          preItem,
          nextItemIndex,
          nextItem,
          realPreItemIndex,
          realNextItemIndex,
          lastItemIndex;

        const itemIndex = this.tree.menuList.indexOf(menuItem);
        const copyMenus = JSON.parse(JSON.stringify(this.tree.menuList));
        switch (type) {
          case 0:
            preItemCopy = copyMenus.find(
              d => d.menuDepth === this.menuFormData.data.menuDepth && d.parentId === this.menuFormData.data.parentId,
            );

            if (preItemCopy) {
              let firstItemIndex = this.tree.menuList.findIndex(d => d.id === preItemCopy.id);

              this.tree.menuList.splice(itemIndex, 1);
              this.tree.menuList.splice(firstItemIndex, 0, menuItem);
            }
            break;
          case 1:
            groupList = copyMenus.filter(
              d => d.menuDepth === this.menuFormData.data.menuDepth && d.parentId === this.menuFormData.data.parentId,
            );
            preItemIndex = groupList.findIndex(d => d.id === this.menuFormData.data.id) - 1;

            if (0 > preItemIndex) return;

            preItem = groupList[preItemIndex];

            realPreItemIndex = this.tree.menuList.findIndex(d => d.id === preItem.id);
            this.tree.menuList.splice(itemIndex, 1);
            this.tree.menuList.splice(realPreItemIndex, 0, menuItem);

            break;
          case 2:
            groupList = copyMenus.filter(
              d => d.menuDepth === this.menuFormData.data.menuDepth && d.parentId === this.menuFormData.data.parentId,
            );
            nextItemIndex = groupList.findIndex(d => d.id === this.menuFormData.data.id) + 1;

            if (groupList.length - 1 < nextItemIndex) return;

            nextItem = groupList[nextItemIndex];

            realNextItemIndex = this.tree.menuList.findIndex(d => d.id === nextItem.id);
            this.tree.menuList.splice(itemIndex, 1);
            this.tree.menuList.splice(realNextItemIndex, 0, menuItem);

            break;
          case 3:
            nextItemCopy = copyMenus
              .reverse()
              .find(d => d.menuDepth === this.menuFormData.data.menuDepth && d.parentId === this.menuFormData.data.parentId);

            if (nextItemCopy) {
              lastItemIndex = this.tree.menuList.findIndex(d => d.id === nextItemCopy.id);
              this.tree.menuList.splice(itemIndex, 1);
              this.tree.menuList.splice(lastItemIndex + 1, 0, menuItem);
            }

            break;
          default:
            break;
        }
      },
      /**
       * 메뉴 순서 저장
       */
      onSaveSort() {
        for (let index = 0; index < this.tree.menuList.length; index++) {
          this.tree.menuList[index].menuOrd = index;
        }
        this.saveMenu(this.tree.menuList);
      },
      noDataText(length) {
        if (length === 0) return '추가된 메뉴가 없습니다.';
      },
      /**
       * 드래그 변경
       * @param e
       */
      onDragChange(e) {
        this.tree.selectedRowKeys = [];
        let visibleRows = e.component.getVisibleRows(),
          sourceNode = e.component.getNodeByKey(e.itemData.id),
          targetNode = visibleRows[e.toIndex].node;

        while (targetNode && targetNode.data) {
          if (targetNode.data.id === sourceNode.data.id) {
            e.cancel = true;
            break;
          }
          targetNode = targetNode.parent;
        }
      },
      /**
       * 메뉴 순서 변경
       * @param e
       */
      onReorder(e) {
        let visibleRows = e.component.getVisibleRows(),
          sourceData = e.itemData,
          targetData = visibleRows[e.toIndex].node.data,
          menuList = [...this.tree.menuList];

        if (e.dropInsideItem) {
          e.itemData.Head_ID = targetData.id;
          e.component.refresh();
        } else {
          let sourceIndex = menuList.indexOf(sourceData),
            targetIndex = menuList.indexOf(targetData);

          if (sourceData.Head_ID !== targetData.Head_ID) {
            sourceData.Head_ID = targetData.Head_ID;
            if (e.toIndex > e.fromIndex) {
              targetIndex++;
            }
          }
          menuList.splice(sourceIndex, 1);
          menuList.splice(targetIndex, 0, sourceData);
          this.tree.menuList = menuList;
        }
      },
      selectionChanged(e) {
        this.tree.selectedRowsData = e.component.getSelectedRowsData(this.tree.selectionMode);
      },
      /** @description : 트리 로우 데이터 클릭 이벤트 */
      onClickRow(row) {
        let rowData = row.data;
        if (rowData) {
          this.menuFormData.data = cloneObj(rowData); // 양방향 바인딩 방지를 위해 데이터 복사
          this.tree.focusedRowKey = rowData.id;
        }
      },
      /**
       * 메뉴 리스트 가져오기
       * @returns {Promise<void>}
       */
      async selectMenuList() {
        const payload = {
          actionname: 'MENU_LIST_ALL',
          data: {
            viewFl: `${this.$_enums.common.stringViewFlag.YES.value},${this.$_enums.common.stringViewFlag.NO.value}`,
            sort: '+menuDepth,+menuOrd',
          },
        };
        const res = await this.CALL_API(payload);
        if (isSuccess(res)) {
          this.tree.menuList = res.data.data;
        }
      },
      onCancelFormData() {
        this.clearData();
        this.selectMenuList();
      },
      clearData() {
        this.tree.focusedRowKey = null;
        this.tree.selectedRowKeys = [];
        this.menuFormData.data = {};
        this.modal.menuConfigHistories = null;
        if (this.modal.contentData) {
          this.modal.contentData.description = null;
          this.modal.contentData.pageData = null;
        }
      },
      /**
       * 선택된 메뉴의 하위메뉴를 삭제한다.
       * @param data
       * @returns {Promise<boolean>}
       */
      async onDeleteData(data) {
        let childrenArr = this.selectChildrenById([data.row.node], data.row.node.key);
        let deletionIdList = [childrenArr.id, ...childrenArr.childs].map(d => d);

        let msgContents = `삭제하시겠습니까?`;
        if (data.row.node.children.length > 0) {
          msgContents = `삭제시 하위메뉴까지 삭제됩니다.<br/>삭제하시겠습니까?`;
        }

        if (await this.$_Confirm(msgContents)) {
          const payload = {
            actionname: 'MENU_LIST_DELETE',
            data: { data: deletionIdList },
            useErrorPopup: true,
          };

          const res = await this.CALL_API(payload);
          if (isSuccess(res)) {
            await this.$_Msg(this.$_msgContents('CMN_SUC_DELETE'));
            this.clearData();
            await this.selectMenuList();
          }
        }
      },
      /**
       * 선택된 메뉴의 하위메뉴를 가져온다.
       * @param arr
       * @param key
       * @returns {{}}
       */
      selectChildrenById(arr, key) {
        return findId(arr, key);

        function findId(arr, key) {
          let output = {};
          arr.forEach(o => {
            if (o.data.id === key) {
              output.id = o.data.id;
              output.childs = (o.children && getArrayOfChildren(o.children, [])) || [];
            } else if (o.children && o.children.length) {
              output = findId(o.children, o.data.id);
            }
          });
          return output;
        }

        function getArrayOfChildren(arr, existingChildren) {
          arr.forEach(o => {
            existingChildren.push(o.data.id);
            o.children && getArrayOfChildren(o.children, existingChildren);
          });
          return existingChildren;
        }
      },
      async createdData() {
        await this.$_setPageSettingConfig();
        await this.selectMenuList();
      },
      /**
       * 메뉴 유형 툴팁 반환
       *
       * @param menuTypeCd
       * @returns {*}
       */
      getMenuTypeTooltip(menuTypeCd) {
        let tooltip = '';
        switch (menuTypeCd) {
          case this.enums.common.menuType.NORMAL_MENU.value:
            tooltip = '일반 페이지의 상위 메뉴(대메뉴/중메뉴/소메뉴)';
            break;
          case this.enums.common.menuType.LINK.value:
            tooltip = '링크 이동을 위한 메뉴';
            break;
          case this.enums.common.menuType.NORMAL_PAGE.value:
            tooltip = '일반 페이지';
            break;
          case this.enums.common.menuType.REPORT.value:
            tooltip = '보고서 관련 메뉴';
            break;
          default:
            tooltip = '';
            break;
        }
        return tooltip;
      },
    },
    created() {
      this.createdData();
    },
  };
</script>
<style scoped>
  #menu_config_menu_icon {
    border: 0;
  }

  #menu_config_menu_icon .dx-texteditor-input {
    font-size: 13px;
    padding-top: 10px;
    padding-left: 4px;
  }
</style>
